var dropdownSubmenuElementList = [].slice.call(document.querySelectorAll('.dropdown-submenu-toggle'));

var submenuList = dropdownSubmenuElementList.map(function(e) {
  e.onclick = function(e){
    e.target.parentNode.querySelector('ul').classList.toggle('show');
    e.stopPropagation();
    e.preventDefault();
  }
});

class VideoResponser {
  constructor(selector) {
    const $video = document.querySelector(selector);
    this.options = {
      selector,
      breakpoints: { default: { src: $video.getAttribute('data-src') } }
    };

    $video.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-from-breakpoint')] = { src: element.getAttribute('data-src') });
    $video.innerHTML = '';

    this.responseVideo(this.options);
    this.resizer();
  }

  resizer() {
    window.addEventListener("resize", () => this.responseVideo(this.options));
  }

  responseVideo(options) {
    const {selector, breakpoints} = options;
    let $video = document.querySelector(selector);
    const widthNow = $video.getAttribute('data-width-now') || null;
    const maxBreakpoint = Math.max(Object.keys(breakpoints).filter(key => key >= document.body.clientWidth));
    const nowBreakpoint = maxBreakpoint || 'default';

    if(widthNow && widthNow == nowBreakpoint) return;

    $video.setAttribute('data-width-now', nowBreakpoint);
    $video.src = breakpoints[nowBreakpoint].src;
  }
}

document.addEventListener("DOMContentLoaded", function() {
  if (document.querySelector("#hero-video")) {
    new VideoResponser("#hero-video");
  }

  var mainNav = document.getElementById('mainNav'),
    collapseComponent = document.querySelector('.collapse-component');

  mainNav.addEventListener('show.bs.collapse', function() {
    document.body.classList.add('overflow-hidden');
    collapseComponent.classList.add('shown');
  });

  mainNav.addEventListener('hide.bs.collapse', function() {
    document.body.classList.remove('overflow-hidden');
    collapseComponent.classList.remove('shown');
  });

  if (document.querySelector(".hero-ticker")) {
    var e = document.querySelectorAll(".hero-ticker .ticker-item"),
      t = 0;

    setInterval(function () {
      e[t].classList.remove("active"), (t = (t + 1) % e.length), e[t].classList.add("active");
    }, 2000);
  }

  (function () {
    $('[data-widget="download-filter"]').each(function () {
      var $container = $(this);
      var $categoryFilter = $container.find('[data-target="category-filter"]');
      var $keywordFilter = $container.find('[data-target="keyword-filter"]');
      var $resultsCounter = $container.find('[data-target="results-counter"]');
      var $listItems = $container.find('[data-target="list-item"]');
      var $filteredListItems = [];
      var $showMore = $container.find('[data-target="show-more"]');
      var pageSize = $container.data('pageSize') || 20;
      var itemsToShow = pageSize;

      function update() {
        updateFilteredListItems();
        updateResultsCounter();
        updateListItems();
      }

      function updateFilteredListItems() {
        var selectedCategory = $categoryFilter.val();
        var selectedKeyword = $keywordFilter.val();

        if (!selectedCategory && !selectedKeyword) {
          $filteredListItems = $listItems;
        } else {
          $filteredListItems = $listItems.filter(function () {
            var categories = $(this).data('categories');
            var keywords = $(this).data('keywords');

            console.log(categories, keywords, selectedCategory, selectedKeyword);

            return (
              (!selectedCategory || (categories && -1 !== String(categories).split(',').indexOf(String(selectedCategory))))
              && (!selectedKeyword || (keywords && -1 !== String(keywords).split(',').indexOf(String(selectedKeyword))))
            );
          });
        }
      }

      function updateResultsCounter() {
        var total = $filteredListItems.length;

        if (1 === total) {
          $resultsCounter.text($resultsCounter.data('singularText'));
        } else {
          $resultsCounter.text($resultsCounter.data('pluralText').replace(/%%%COUNT%%%/, total));
        }
      }

      function updateListItems() {
        var counter = 0;

        $listItems.each(function () {
          var $listItem = $(this);
          var visible = counter < itemsToShow && -1 !== $filteredListItems.index($listItem);

          $listItem.toggle(visible);

          if (visible) {
            ++counter;
            $listItem.toggleClass('section-gray', counter % 2 === 1);
          }
        });

        $showMore.toggle(itemsToShow < $filteredListItems.length);
      }

      update();
      $categoryFilter.on('change', function () {
        itemsToShow = pageSize;
        update();
      });
      $keywordFilter.on('change', function () {
        itemsToShow = pageSize;
        update();
      });
      $showMore.on('click', function (event) {
        event.preventDefault();

        itemsToShow += pageSize;
        update();
      });
    });
  })();
});

$(document).ready(function () {
  var $window = $(window),
    $link = $('<a href="#top" class="scrolltop"></a>')
      .hide()
      .on('click', function (event) {
        event.preventDefault();

        window.scrollTo(0, 0);
      })
      .appendTo('body');

  $window.on('scroll', function () {
    if ($window.scrollTop() > 100) {
      $link.fadeIn();
    } else {
      $link.fadeOut();
    }
  });

  var mainMenu = document.getElementById('mainNav'),
    firstDropdown = document.querySelector('.navbar-main > li:first-child > .dropdown-toggle');

  firstDropdown.addEventListener('show.bs.dropdown', function () {
    $('.navbar-toggler').addClass('toggler-white');
  });
  firstDropdown.addEventListener('hide.bs.dropdown', function () {
    $('.navbar-toggler').removeClass('toggler-white');
  });

  mainMenu.addEventListener('show.bs.collapse', function () {
    if ($('.collapse-component').hasClass('shown')) {
      if ($('.navbar-main > li:first-child > .dropdown-toggle').hasClass('show')) {
        $('.navbar-toggler').addClass('toggler-white');
      }
    }
  });
  mainMenu.addEventListener('hide.bs.collapse', function () {
    $('.navbar-toggler').removeClass('toggler-white');
  });
});

(function () {
  var rellax = null;

  function handleRellax() {
    var breakpoint = 1024,
      shouldBeEnabled = breakpoint <= document.body.clientWidth,
      isEnabled = null !== rellax;

    if (shouldBeEnabled !== isEnabled) {
      if (shouldBeEnabled) {
        rellax = new Rellax('.rellax', {
          center: true,
        });
      } else if (rellax) {
        rellax.destroy();
        rellax = null;
      }
    }
  }

  document.addEventListener('DOMContentLoaded', handleRellax);
  window.addEventListener('resize', handleRellax);
})();
